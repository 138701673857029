import axios from "axios"
import {Storage} from "@/services/Storage";
import {responses} from "@/utils/responses";

export default {
    name: 'stockCategories',
    state: {
        currentCategory: '',
        subCategory: '',
        sub2Category: '',
        categories: [],
    },
    getters: {
         getCategories: (state) => state.categories,
        getSubDepartments: (state) => (dep) => {
            let data = state.categories.find(main => main.name.toLowerCase() === dep.toLowerCase())
            if (data) {
                return data.children
            } else {
                return []
            }
        },
        getSub2Departments: (state, getters) => (dep, sub) => {
            let data = getters.getSubDepartments(dep).find(sub2 => sub2.name.toLowerCase() === sub.toLowerCase())
            if (data) {
                return data.children
            } else {
                return []
            }
        },
        getCurrentCategory: (state) => state.currentCategory,
        getSubCategory: (state) => state.subCategory,
        getSubCategory2: (state) => state.sub2Category,
    },

    mutations: {
        setCategories: async (state, payload) => {
            if (!payload) payload = await Storage.getCategories();
            state.categories = payload
        },
        setCurrentCategory: (state, payload) => state.currentCategory = payload,
        setSubCategory: (state, payload) => state.subCategory = payload,
        setSubCategory2: (state, payload) => state.sub2Category = payload,
    },
    actions: {
        createStockCategoryDialog: (context) => {
            return Swal.fire({
                title: 'Create New Stock Category',
                input: 'text',
                inputAutoTrim: true,
                inputAttributes: {
                    autocapitalize: 'on'
                },
                showCancelButton: true,
                confirmButtonText: 'Create',
                showLoaderOnConfirm: true,
                preConfirm: (category) => {
                    if (!category) return Swal.showValidationMessage(`Category name is required`)
                    return context.dispatch('createMainCategory', category)
                        .catch(error => Swal.showValidationMessage(error))
                },
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    if (result.value === 'Department already exists !') {
                        return Swal.fire(
                            'FAILED',
                            result.value,
                            'error'
                        )
                    }
                    return Swal.fire(
                        'Success',
                        `Stock Category ${result.value} created successfully`,
                        'success'
                    )
                }
            })
        },

        getCategories: async (context) => {
            context.commit('setCategories')
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(serverApi + `category/${store.StoreDB}/getAsTree`)
                .then(({data}) => {
                    data = data.filter(category => category.name.trim() !== '')
                        .sort((a, b) => a.name.localeCompare(b.name))
                    context.commit('setCategories', data)
                })
        },
        createMainCategory: (context, category) => {
            return context.dispatch('createUpdateMain', {
                id: null,
                name: category,
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },

        editMainCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                name: payload.name,
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },

        createUpdateMain: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi + `category/${store.StoreDB}`, {
                id: payload.id,
                MainCatName: $utils.toPascalCase(payload.name),
                SubCatName: payload.sub ? $utils.toPascalCase(payload.sub) : null,
                SubCatName2: payload.sub2 ? $utils.toPascalCase(payload.sub2) : null,
            }).then(() => $utils.toPascalCase(payload.name)).catch((err) => errorHandler.tomcatError(err))
                .finally(() => context.dispatch('getCategories'))
        },

        editSubCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: payload.id,
                name: payload.mainCat,
                sub: payload.sub
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        },

        createSubCategory: (context, payload) => {
            return context.dispatch('createUpdateMain', {
                id: null,
                name: payload.main,
                sub: payload.sub
            }).then(res => res).catch(err => {
                throw new Error(err)
            })
        }

    }
}
