import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'stock-transfer',
    state: {
        allStores : [],
        franchiseSetting:null,
        itemsToSend : []
    },
    getters: {
        getStores : (state) => state.allStores,
        getFranchiseSettings: (state) => state.franchiseSetting,
        getItemsToSend : (state) => state.itemsToSend,
    },
    mutations: {
        setFranchiseSettings : async (state, payload) =>{
            if (payload) payload = await Storage.getFranchiseSettings();
            state.franchiseSetting= payload;
        },
        setAllStores : async (state,payload) => {
            if (!payload){
                let stores = await Storage.getAllStores();
                if (!stores){
                    let defaultStore = await Storage.getDefaultStore();
                    stores = [];
                    stores.push(defaultStore);
                }
                payload = stores;
            }
            state.allStores = payload;
        },
        addToItemsToSend: (state,payload) => state.itemsToSend.push(payload),
        removeFromItemsToSend: (state,plu) => state.itemsToSend = state.itemsToSend.filter(item=>item.Plu !== plu),
        clearItemsToSend : (state) => state.itemsToSend = [],
    },
    actions: {
        getAllFranchiseSettings: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `franchise_settings/${store.StoreDB}`)
                .then(({data})=>context.commit(`setFranchiseSettings`,data))
                .catch(err=>errorHandler.tomcatError(err))
        },
        getAllStores: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi+`stores/getByGroup`,{
                params : { group : store.Group }
            }).then(({data})=>context.commit(`setAllStores`,data.sort((a, b) => a.StoreName.localeCompare(b.StoreName))));
        },
        getIncomingTransfers: async (context,payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi+`franchise_stockpending/${store.StoreDB}/search`,payload)
                .then(({data}) => data)
                .catch(err=>errorHandler.tomcatError(err))
        },
        getTransferByDocument: async (context,obj)=> {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi+`franchise_transfer/${store.StoreDB}/transferLine/${obj.documentNumber}?senderID=${obj.senderID}`)
                .then(({data})=>data)
                .catch(err=>errorHandler.tomcatError(err))
        },

        rejectTransferByDocumentNumber : async (context , payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi+`franchise_stockpending/${store.StoreDB}/rejectTransferByDocumentNumber`,payload)
                .then(()=>context.dispatch(`getAllStock`))
                .catch(err=>errorHandler.tomcatError(err,true))
        },

        acceptTransferByDocumentNumber : async (context , obj) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser()
            let payload = {
                documentNumber : obj.documentNumber,
                senderID : obj.senderID,
                user : user.Username,
                pc : localStorage.getItem(`_capuid`)
            }
            return axios.post(franchiseTomcatApi+`franchise_stocksent/${store.StoreDB}/acceptTransferByDocumentNumber`,payload)
                .then(({data})=> {
                    return Promise.all([
                        context.dispatch(`printGRV`,data),
                        context.dispatch(`getAllStock`)
                    ])
                }).catch(err=>errorHandler.tomcatError(err,true))
        },
        transferStock: async (context, storeID) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser()
            let obj = {
                storeID : storeID,
                items : context.getters.getItemsToSend,
                user : user.Username,
                pc : localStorage.getItem(`_capuid`)
            };
            return axios.post(franchiseTomcatApi+`franchise_stocksent/${store.StoreDB}/inititateTransfer`,obj)
                .then(()=>context.commit(`clearItemsToSend`))
                .catch(err=>errorHandler.tomcatError(err,true))
        },
        getStockInBothStores: async (context , payload) => {
            let store = await Storage.getDefaultStore();
            let senderID = store.CustomerID;
            let receiverID = payload.storeID;
            let searchFilter = {
                Plu : payload.Plu,
                Description : payload.Description,
                limit : 50
            }
            return axios.post(franchiseTomcatApi+`franchise/search_stock/match/${senderID}/${receiverID}`,searchFilter)
                .then(({data})=>data)
                .catch(err=>errorHandler.tomcatError(err))
        }
    }
}
