import axios from "axios";
import {Storage} from "@/services/Storage";

export default {
    name: 'tables',
    state: {
        allTables: [],
        isTabOpen: false,
    },
    getters: {
        getAllTables: (state) => state.allTables,
        getIsTabOpen: (state) => state.isTabOpen,
        getTableBySaleNum: (state) => (saleNum) => (state.allTables) ? state.allTables.find(s => s.saleNumber === saleNum) : undefined,
    },

    mutations: {
        setAllTables: (state, payload) => {
            if (payload) {
                return state.allTables = payload
            } else {
                return Storage.getAllTables ().then(res => state.allTables = (res ? res : []))
            }
        },

        setIsTabOpen: (state, data) => state.isTabOpen = data,

        createTable: (state, payload) => {
            return Storage.createTable(payload).then(() => {
                return Storage.getAllTables().then(res => state.allTables = res)
            });
        },

        editTable: (state, payload) => {
            return Storage.editTable(payload).then(() => {
                return Storage.getAllTables().then(res => state.allTables = res)
            })
        },

        deleteTable: (state, sale_number) => {
            return Storage.deleteTable(sale_number).then(() => {
                return Storage.getAllTables().then(res => state.allTables = res)
            });
        },
    },
    actions: {
        getAllTables: async (context) => {
            let loggedIn = await auth.isCashierLoggedIn();
            if (loggedIn) {
                let store = await Storage.getDefaultStore();
                let user = await Storage.getCurrentUser()
                return axios.get(serverApi + `tables/${store.StoreDB}/${user.Username}`)
                    .then((res) => context.commit('setAllTables', res.data))
                    .catch((err) => errorHandler.tomcatError(err))
            }
        },
        saveNewTable: async (context, payload) => {
            let saleLines = context.getters.getSaleLines;
            for (let i = 0; i < saleLines.length; i++) {
                let line = saleLines[i];
                saleLines[i].Line = Number(line.Qty) * Number(line.Unit);
            }
            let obj = {
                SaleNum: context.getters.getCurrentSale,
                Name: (payload.name && payload.name.length > 0) ? payload.name : "Table " + context.getters.getCurrentSale,
                User: context.getters.getCurrentUser.Username,
                PC: context.getters.getDeviceSettings.PC,
                items: saleLines,
                Customer: payload.Customer,
                discount : parseFloat(context.getters.getSaleDiscount.total),
            }
            return context.dispatch('AddOrUpdateTable', obj)
                .then((res) => context.commit('createTable', res))
                .catch(err => {
                    throw new Error(err)
                });
        },
        AddOrUpdateTable: async (context, payload) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`,`SaveTable`);
            if (!isAllowed) throw new Error(`User not allowed to Save Tables`);
            let store = await Storage.getDefaultStore();
            return axios.post(serverApi + `tables/${store.StoreDB}`, payload)
                .then(({data}) => {
                    context.commit('clearSale');
                    return data
                }).catch((err) => errorHandler.tomcatError(err))
        },
        updateTable: (context, payload) => {
            let saleLines = context.getters.getSaleLines;
            for (let i = 0; i < saleLines.length; i++) {
                let line = saleLines[i];
                saleLines[i].Line = Number(line.Qty) * Number(line.Unit);
            }
            let data = {
                SaleNum: context.getters.getCurrentSale,
                Name: (payload.name && payload.name.length > 0) ? payload.name : "Table " + context.getters.getCurrentSale,
                User: context.getters.getCurrentUser.Username,
                PC: context.getters.getDeviceSettings.PC,
                items: saleLines,
                Customer: payload.Customer,
                discount : parseFloat(context.getters.getSaleDiscount.total),
            };
            return context.dispatch('AddOrUpdateTable', data)
                .then((res) => context.commit('editTable', res))
        },
        removeTable: async (context, sale_number) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`,`Void`);
            if (!isAllowed) return false;
            let store = await Storage.getDefaultStore();
            return axios.delete(serverApi + `tables/${store.StoreDB}/void/${sale_number}`)
                .then(async () => {
                    await context.dispatch('getAllTables');
                    return true;
                }).catch((err) => errorHandler.tomcatError(err))
        },
        emailQuote : async (context,payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(franchiseTomcatApi+`emailrecord/${store.StoreDB}/emailQuote`,payload)
                .catch((err) => errorHandler.tomcatError(err))
        }
    }
}
