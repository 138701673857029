<template>
  <div class="container-fluid navigation-bar p-1 px-2">

    <button @click="toggle" class="burger">
      <font-awesome-icon icon="fa-solid fa-bars"/>
    </button>

    <div class="navbar-logo"></div>
    <div class="navigation-box"></div>
    <p class="number" v-if="isMobile">
      <font-awesome-icon icon="fa-phone"/>
      +260 775113149
    </p>

    <div class="user-settings" >
      <p class="number" v-if="!isMobile">
        <font-awesome-icon icon="fa-phone"/>
        +260 775113149
      </p>

      <button @click="openSmartInvoice" v-if="(!isSmartInvoiced) && isSmartInvoiceLive" class="burger mx-2 red-blinking">
        <font-awesome-icon icon="triangle-exclamation" />
      </button>
      <Notification />


      <a class="burger mx-4" v-if="readyOrders > 0" @click="openKitchenOrders">
        <span class="text-warning notification-badge">{{ readyOrders }}</span>
        <font-awesome-icon icon="bowl-food"/>
        <audio ref="audioPlayer" src="@/assets/notification.mp3"></audio>
      </a>

      <button @click="syncData" class="burger">
        <font-awesome-icon icon="fa-solid fa-arrows-rotate"/>
      </button>
    </div>
    <custom-modal title="Smart Invoice Sign up Pending" ref="smartInvoiceModal">
      <div v-if="hasSubmittedDocuments">
        <p>Documents submitted successfully.</p>
        <button @click="viewTicket" class="btn btn-primary">View Ticket Status</button>
      </div>

      <div v-else class="row justify-content-center">
        <div class="col-md-8 text-center">
          <p class="mb-3">You have not registered for smart invoicing. Please sign up now:</p>
          <a class="btn btn-primary tub" @click="goToSmartInvoiceScreen">
            Sign up now
          </a>
        </div>
      </div>

    </custom-modal>
  </div>
</template>

<script>
import CustomModal from "@/components/layout/utils/CustomModal.vue"
import Notification from "@/components/layout/navigation/Notification";
export default {
  name: "NavigationBar",
  beforeMount() {
    this.checkReadyOrders()
  },
  mounted() {
    this.setMobileStatus();
    window.addEventListener('resize', this.setMobileStatus);

    this.interval = setInterval(() => {
      this.checkReadyOrders();
    }, 30000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setMobileStatus);
    clearInterval(this.interval);
  },
  components: {Notification, CustomModal},
  computed: {
    readyOrders() {
      return this.$store.getters.getReadyOrdersCount;
    },
    isSmartInvoiced(){
      return this.$store.getters.getSmartInvoicingStatus;
    },
    isSmartInvoiceLive(){
      return this.$store.getters.getSmartInvoicingLive;
    },
    hasSubmittedDocuments(){
      return this.$store.getters.getDocSubmissionStatus;
    },
  },
  props: {
    toggle: {
      type: Function,
      require: true
    },
  },
  data(){
    return {
      isMobile: false,
    }
  },
  methods: {
    setMobileStatus() {
      this.isMobile = window.innerWidth <= 1239;
    },
    syncData() {
      this.$store.dispatch('syncData');
      return window.location.reload(true);
    },
    openSmartInvoice() {
      return this.$refs.smartInvoiceModal.openModal();
    },
    tawkTo() {
      Swal.fire({
        title: "Support Number",
        text: "+260 95 8168829",
        icon: "info",
      });
      // return window.open('https://tawk.to/LytSupport', '_self');
    },
    viewTicket() {
      this.closeModal();
      let id = this.hasSubmittedDocuments;
      return this.$router.push({ name : `TicketDetails` , params : {id} })
    },
    checkReadyOrders() {
      return this.$store.dispatch(`checkReadyOrders`)
          .then(res => {
            try {
              document.getElementById(`interact-btn`).click();
            } catch (e) {
            }
            if (res) return this.playSound();
          });
    },
    closeModal() {
      return this.$refs.smartInvoiceModal.closeModal();
    },
    playSound() {
      this.$refs.audioPlayer.play();
    },
    goToSmartInvoiceScreen() {
      this.closeModal();
      return this.$router.push({name : `smart-invoicing-setup`});
    },
    openKitchenOrders() {
      return this.$router.push({name: `kitchen-orders`})
    }
  }
}
</script>

<style scoped>
.number{
  white-space: none;
  margin-right: 1rem;
  padding-top: 1rem;
  height: 40px;
  background: transparent;
  color: white;
  font-size: clamp(0.9rem, 2.5vw, 1.2rem);
}
.burger {
  width: 40px;
  height: 40px;
  background: transparent;
  color: white;
  font-size: 30px;
}

.user-settings {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.notification-badge {
  position: fixed;
  margin-right: 5px;
  color: white;
  border-radius: 5px;
  padding: 1px 6px 1px 6px;
  font-size: 15px;
  opacity: 0; /* Initially hidden */
  transform: scale(0); /* Initially small size */
  transition: opacity 0.3s ease, transform 0.3s ease;
}
.red-blinking {
  color: red;
  animation: blinker 1.5s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.burger .notification-badge {
  opacity: 1;
  transform: scale(1);
  animation: notification-pulse 1.5s infinite;
}

@keyframes notification-pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
  50% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 yellow;
  }
}
</style>
