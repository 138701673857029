import Vue from 'vue'
import Vuex from 'vuex'
import taxModule from './modules/tax-module';
import salesModule from "@/store/modules/Sale/sales-module";
import emailModule from "@/store/modules/Sale/email-module"
import stockModule from "@/store/modules/Stock/stockmodule";
import stockCategories from "@/store/modules/Stock/stockcategory-module";
import floatModule from "@/store/modules/Sale/float-module";
import tableModule from "@/store/modules/Sale/table-module";
import cookingInstruction from "@/store/modules/Sale/cookingInstruction";
import stockTake from '@/store/modules/Stock/stock-take-module';
import paymentMethods from '@/store/modules/Sale/payment-methods-module.js';
import accounts from "@/store/modules/Accounts/accounts";
import kazangModule from "@/store/modules/Sale/kazang_module";
import supplierModule from "@/store/modules/supplier-module.js";
import stockReceivedModule from "@/store/modules/Stock/stock-received-module";
import stockTransferModule from "@/store/modules/Stock/stock-transfer-module";
import kazangPayment from "@/store/modules/Kazang/payment";
import kazangSignUp from "@/store/modules/Kazang/sign-up-module";
import customInstall from "@/store/modules/Sale/custom-install-module";
import billingModule from "@/store/modules/billing-module";
import backOfficeModule from "@/store/modules/backoffice-module";
import ticketModule from './modules/ticket-module'
import timeModule from './modules/time-module';
import axios from "axios";
import {Storage} from "@/services/Storage"
import {responses} from "@/utils/responses";
import userModule from "@/store/modules/user_module";
import kitchenOrdersModule from "@/store/modules/Sale/kitchen-orders-module.js";
import router from "@/router";
import queryStringify from "qs-stringify";
import Swal from "sweetalert2";
import ticket_notes_module from "@/store/modules/ticket_notes_module";

import VuexPersistence from 'vuex-persist'
import {set, get, del} from 'idb-keyval'

const indexedDBStorage = {
    getItem: async (key) => {
        const value = await get(key);
        console.log('Getting from IndexedDB:', {key, value});
        return value;
    }, // Retrieve data from IndexedDB
    setItem: async (key, value) => {
        console.log('Saving to IndexedDB:', {key, value});
        await set(key, value); // Save data to IndexedDB
    },
    removeItem: async (key) => {
        console.log('Removing From IndexedDB:', key);
        await del(key)
    }, // Remove data from IndexedDB
};


Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
    storage: indexedDBStorage,
    asyncStorage: true,
    restoreState: async (key) => {
        try {
            let value = await get(key);
            console.log('Restoring state from IndexedDB:', {key, value});
            if (value) {
                if (typeof value === 'string') value = JSON.parse(value);
                value.loading = false;
                value.isHydrated = true;
                return value;
            }
            return {isHydrated: true};
        } catch (err) {
            console.error('Error restoring state:', err);
            return {};
        }
    },
    saveState: async (key, value) => {
        try {
            const cleanState = cleanUpState(value);
            // console.log('Saving state to IndexedDB:', { key, value });
            await set(key, cleanState);
        } catch (err) {
            console.error('Error saving state:', err);
        }
    }
});

function cleanUpState(state) {
    return JSON.parse(JSON.stringify(state));
}

let CurrentTime = function () {
    let date = new Date();
    let d = date.toISOString().split('T')[0]
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    let t = hour + ':' + minute + 'hrs';
    return d + ` at ` + t;
}
const store = new Vuex.Store({
    state: {
        zra_details: null,
        defaultStore: null,
        device_settings: null,
        baseCurrency: {Symbol: `K`},
        lastUpdated: '',
        loading: false,
        isHydrated: false,
        registrationDetails: null,
        isUpdateAvailable: false,
        isSmartInvoicing: false,

    },
    getters: {
        isHydrated: (state) => state.isHydrated,
        getSmartInvoicingStatus: (state) => state.isSmartInvoicing,
        getZRADetails: (state) => state.zra_details,
        getRegistrationDetails: (state) => state.registrationDetails,
        getLoadingState: (state) => state.loading,
        getDefaultStore: (state) => state.defaultStore,
        getDeviceSettings: (state) => state.device_settings,
        getLasUpdated: (state) => state.lastUpdated,
        getBaseCurrency: (state) => state.baseCurrency,
        getUpdateAvailability: (state) => state.isUpdateAvailable,
        currentDate: () => {
            let date = new Date();
            return date.toISOString().split('T')[0];
        },

        currentTime: () => {
            let date = new Date();
            let d = date.toISOString().split('T')[0]
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let t = hour + ':' + minute;
            return d + `T` + t;
        },
    },
    mutations: {
        setSmartInvoicingStatus: (state, payload) => state.isSmartInvoicing = payload,
        setMobileUser: (state, payload) => Storage.setMobileUser(payload),
        toggleNavigation: (state, width) => state.navigation = width,
        setLoadingState: (state, payload) => state.loading = payload,
        setZraDetails: async (state, data) => {
            state.zra_details = data;
        },
        setAllStores: async (state, payload) => {
            if (payload) payload = await Storage.getAllStores();
            state.allStores = payload ? payload : [];
        },
        setLastUpdated: (state, payload) => state.lastUpdated = payload,
        setUpdateAvailability: (state, payload) => state.isUpdateAvailable = payload,
        setDeviceSettings: async (state, payload) => {
            state.device_settings = payload
        },

        setBaseCurrency: async (state, payload) => {
            state.baseCurrency = payload
        },

        setRegistrationDetails: (state, payload) => {
            state.registrationDetails = payload
        },


        setDefaultStore: (state, data) => {
            state.defaultStore = data
            return Storage.getDefaultStore(data)
        },
    },
    actions: {
        signOutOfStore: async (context) => {
            let user = await Storage.getCurrentUser();
            if (!!user && isValidString(user.Username)) {
                context.dispatch('recordLogout', user.Username);
            }
            await Promise.all([
                context.commit(`setCurrentUser`, {
                    Type: "",
                    Username: "",
                    id: "",
                    Surname: "",
                    Name: "",
                    Password: ""
                }),
                context.commit(`setDefaultStore`, null),
                context.commit(`setRegistrationDetails`, null),
                context.commit(`setDeviceSettings`, null),
                context.commit(`setStock`, []),
                context.commit(`setStockSetting`, []),
                context.commit(`setAllStores`, []),
                context.commit(`setAllUsers`, []),
                context.commit(`setAllSecurities`, []),
                context.commit(`setDiscountSpecials`, []),
                context.commit(`setAllSecurities`, []),
                context.commit(`setComboSpecials`, []),
                context.commit(`setMultiSpecials`, []),
                context.commit(`setBaseCurrency`, {Symbol: `K`}),
                context.commit('setCurrentSale', 0),
                context.commit('setCurrentLines', []),
                context.commit('setDebtors', []),
                context.commit('setCustomer', ''),
            ])
            console.log(`Store Info Cleared`);
            },
        posLogout: async (context, payload) => {
            let loggedIn = context.getters.getCurrentUser
            let user = loggedIn.Username;
            if (user === 'Admin') {
                user = localStorage.getItem('supportAgent');
                localStorage.removeItem('supportAgent');
            }
            const auth = {
                Type: "",
                Username: "",
                id: "",
                Surname: "",
                Name: "",
                Password: ""
            }
            await Storage.setCurrentUser(auth)
            let getCurrentUser = await Storage.getCurrentUser();
            await context.commit(`setCurrentUser`, auth)
            await context.dispatch('recordLogout', user)
            console.log(loggedIn)
            console.log(getCurrentUser)
            return router.push({name: 'poslogin'})
        },
        async migrateLocalStorageToIndexedDB(context) {
            try {
                // Step 1: Retrieve all keys from localStorage
                const keys = Object.keys(localStorage);

                // Step 2: Loop through each key and migrate to IndexedDB
                for (const key of keys) {
                    const value = localStorage.getItem(key);
                    if (value) {
                        try {
                            // Attempt to parse the value as JSON
                            const parsedValue = JSON.parse(value);
                            await set(key, parsedValue); // Store in IndexedDB
                        } catch (error) {
                            console.error(`Error parsing value for key "${key}":`, error);
                            // Store the raw value if parsing fails
                            await set(key, value);
                        }
                    }
                }

                // Step 3: Clear localStorage after migration
                localStorage.clear();
                console.log('Data migrated from localStorage to IndexedDB successfully!');

            } catch (error) {
                console.error('Error migrating data:', error);
                throw error; // Re-throw the error for handling in the component
            }
        },
        syncData: async ({dispatch, commit}) => {
            return axios.all([
                dispatch("getCustomerDetails"),
                dispatch("updateToLatestVersion"),
                dispatch('getAllStock'),
                dispatch('getAllUsers'),
                dispatch('getBaseCurrency'),
                dispatch('getDeviceSettings'),
                dispatch("getCategories"),
                dispatch("isKazangIntegrated"),
                dispatch("getAllPaymentMethods"),
                dispatch("getAllStores"),
                dispatch("getAllActiveSpecials"),
                dispatch("getAllFranchiseSettings"),
                dispatch("getAllSecurities"),
            ]).then(() => {
                commit("setKazangSession");
                commit('setLastUpdated', CurrentTime())
            });

        },
        appLogin: (context, payload) => {
            return axios.post(franchiseTomcatApi + 'AndroidLogin/Attempt', payload)
                .then(async ({data}) => {
                    if (data.StoreDB && data.StoreName) {
                        await Storage.setAdminUser(payload);
                        return context.dispatch(`loginToStore`, data)
                    }
                    await Storage.setAdminUser(payload);
                    return router.push({name: 'setup'});

                }).catch((err) => errorHandler.tomcatError(err)).finally(() => $utils.hideLoading())
        },

        getCustomerDetails: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) {
                return router.push({ name : 'login' });
            }
            return axios.get(franchiseTomcatApi + `stores/getRegistrationDetails`, {
                params: {database: store.StoreDB}
            }).then(({data}) => {
                context.commit(`setRegistrationDetails`, data)
                return context.dispatch("getExpiryDateByCustomerID");
            }).catch((err) => errorHandler.tomcatError(err))
        },
        checkSmartInvoicing: async (context) => {
            let storeDB = await Storage.getCurrentStoreDB();
            if (!storeDB) return;
            let user = await Storage.getCurrentUser();
            return axios.get(franchiseTomcatApi + `GRV/${storeDB}/checkSmartInvoicing`)
                .then(({data}) => {
                    context.commit(`setSmartInvoicingStatus`, data)
                    let isLive = context.getters.getSmartInvoicingLive;
                    if (!data && isLive) {
                        return context.dispatch(`checkIfStoreDocsSubmitted`).then(() => {
                            let hasDocs = context.getters.getDocSubmissionStatus;
                            if (!hasDocs && (!!user && isValidString(user.Username))) {
                                return Swal.fire({
                                    title: 'Register for Smart Invoicing Now',
                                    text: 'You are not registered for smart invoicing. Please register now.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    showCloseButton: true,
                                    allowOutsideClick: false,
                                    confirmButtonText: 'Register',
                                    cancelButtonText: 'Cancel'
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        return router.push({name: 'smart-invoicing-setup'});
                                    }
                                });
                            }
                        })
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },

        confirmationText: (context, payload) => {
            return Swal.fire({
                title: 'Authorization',
                icon: 'info',
                html:
                    '<div style="font-family: Arial, sans-serif;' +
                    '        font-size: 16px;' +
                    '        line-height: 1.5;' +
                    '        color: #333;' +
                    '        background-color: #f7f7f7;' +
                    '        padding: 20px;' +
                    '        border: 1px solid #ccc;' +
                    '        border-radius: 5px;' +
                    '        margin: 20px 0;">' +
                    '<p><strong>Please note:</strong> By clicking on the "Confirm" button, you are authorizing the ' +
                    'automatic monthly deduction of K100.00 from your Kazang account as part of the subscription model ' +
                    'for this software.</p></div>',
                showCloseButton: false,
                showCancelButton: true,
                confirmButtonText: `Confirm`,
                focusConfirm: false,
            }).then((res) => {
                if (res.isConfirmed) {
                    return context.dispatch(`setupStore`, payload)
                }
            })
        },

        setupStore: async (context, payload) => {
            return Swal.fire({
                title: 'Please Enter your Business TPIN',
                icon: 'question',
                input: 'number',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                preConfirm: async (inputValue) => {
                    if (inputValue.length !== 10) return Swal.showValidationMessage(`TPIN must be 10 characters`);
                    if (inputValue === `1018444641`) return Swal.showValidationMessage(`Please enter your Business TPIN`);
                    payload.DeviceID = await Device.getDeviceId();
                    payload.data = await Storage.getAdminUser();
                    payload.tpin = inputValue;
                    return axios.post(franchiseTomcatApi + `AndroidLogin/SecondeReg`, payload)
                        .then(({data}) => context.dispatch(`loginToStore`, data))
                        .catch((err) => errorHandler.tomcatError(err))
                },
            });
        },

        loginToStore: async (context, payload) => {
            await context.commit(`setDefaultStore`, payload);
            await context.dispatch("updateToLatestVersion");
            await context.dispatch(`syncData`);
            let userCount = await context.getters.countNonLytSoftUsers;
            if (userCount === 0 || !userCount) {
                return router.push({name: 'create-initial-user'})
            }
            return router.push({name: 'poslogin'})
        },
        getDeviceSettings: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            let id = localStorage.getItem('_capuid');
            let device = await Device.getDeviceId();
            if (id !== device) {
                try {
                    localStorage.setItem('_capuid', device);
                    await axios.post(franchiseTomcatApi + `UpdateSettings/${store.StoreDB}`, {
                        oldSetting: id,
                        newSetting: device,
                    });
                } catch (e) {
                    return errorHandler.tomcatError(e, false, 'Error on Updating Settings');
                }
            }
            return axios.get(serverApi + `settings/${store.StoreDB}/${device}`)
                .then(({data}) => context.commit('setDeviceSettings', data))
                .catch((err) => errorHandler.tomcatError(err, false, 'Error on Settings'))
        },

        getFloatUsers: async (context) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `cashup/${store.StoreDB}/noneFloat`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        assignFloat: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}float/assign/${store.StoreDB}`, payload)
                .then(({data}) => {
                    if (!data.assigned) {
                        responses.throwErr(data.message)
                        return false;
                    }
                    context.commit('setUserFloat', {
                        user: payload.user,
                        device: payload.device,
                        supervisor: data.supervisor,
                        float: Number(payload.amount)
                    });
                    return true;
                }).catch((err) => errorHandler.tomcatError(err))
        },
        checkFloat: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}float/check/${store.StoreDB}`, payload)
                .catch((err) => errorHandler.tomcatError(err))
        },
        getcashUpDiff: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}cashup/${store.StoreDB}/status`, payload)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        saveSettings: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            return axios.post(`${serverApi}settings/${store.StoreDB}`, payload).then(({data}) => {
                context.commit('setDeviceSettings', data)
                return context.dispatch(`syncData`);
            }).catch((err) => errorHandler.tomcatError(err))
        },
        getStockLocations: async () => {
            let store = await Storage.getDefaultStore();
            return axios.get(`${serverApi}locations/${store.StoreDB}`)
                .then(({data}) => data)
                .catch((err) => errorHandler.tomcatError(err))
        },
        cashUpUser: async (context, payload) => {
            let isAllowed = await context.dispatch(`validateSecurityPermission`, `CompleteCashup`);
            if (!isAllowed) throw new Error(`User not allowed to Cashup`);
            let store = await Storage.getDefaultStore()
            let device = await Storage.getDeviceSettings();
            payload.PC = device.PC
            return axios.post(`${serverApi}cashup/${store.StoreDB}`, payload)
                .then(async ({data}) => {
                    let float = await Storage.getFloat()
                    float = float ? float.filter(f => f.user !== payload.user) : [];
                    let methods = [
                        axios.post(franchiseTomcatApi + `emailrecord/${store.StoreDB}/emailCashup`, {
                            cashup: data.response.cashupNum
                        }),
                        Storage.setFloat(float)
                    ];
                    try {
                        methods.push(Printing.printCashup(JSON.stringify(data)))
                    } catch (e) {
                    }


                    if (store.Group === '1004117420') {
                        axios.get(franchiseTomcatApi + `navision/${store.StoreDB}/cashupPrintDsrCashup/cashup/${data.response.cashupNum}`)
                            .then((res) => {
                                let jsonData = res.data;
                                try {
                                    Printing.printDsrCashup(JSON.stringify(jsonData))
                                } catch (e) {
                                }
                            }).catch(err => errorHandler.tomcatError(err));
                    }

                    return axios.all(methods).finally(() => responses.showInfo('Cash up completed successfully'));
                }).catch((err) => errorHandler.tomcatError(err))
        },

        printCashup: async (context, cashupNumber) => {
            let store = await Storage.getDefaultStore();
            return axios.get(serverApi + `cashup/${store.StoreDB}/${cashupNumber}`)
                .then(({data}) => {
                    try {
                        Printing.printCashup(JSON.stringify(data))
                    } catch (e) {
                    }
                }).catch((err) => errorHandler.tomcatError(err))
        },

        getBaseCurrency: async (context) => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.get(franchiseTomcatApi + `currency/${store.StoreDB}/getBaseCurrency`)
                .then(({data}) => context.commit('setBaseCurrency', data))
                .catch((err) => errorHandler.tomcatError(err))
        },
        upgradeKazangPOSCustomer: async (context, payload) => {
            let obj = queryStringify(payload);
            return axios.put(franchiseTomcatApi + `stores/upgradeKazangPOSCustomer?` + obj)
                .then(() => context.dispatch(`getCustomerDetails`))
                .catch((err) => errorHandler.tomcatError(err))
        },

        checkExpiry: async () => {
            let store = await Storage.getDefaultStore();
            if (!store) {
                return;
            }
            let storeDB = store.StoreDB;
            return axios.get(backOfficeApiGET, {
                params: {
                    route: `other/getExpiryDate?Database=` + storeDB,
                }
            }).then(({data}) => {
                let expireDate = new Date(data);
                let today = new Date();
                return expireDate < today;
            });
        },

        updateToLatestVersion: async () => {
            let store = await Storage.getDefaultStore();
            if (!store) return;
            return axios.put(franchiseTomcatApi + `version/${store.StoreDB}/updateToLatest`);
        },

    },
    modules: {
        salesModule,
        stockModule,
        stockCategories,
        userModule,
        floatModule,
        tableModule,
        emailModule,
        kazangPayment,
        cookingInstruction,
        stockTake,
        paymentMethods,
        accounts,
        kazangModule,
        supplierModule,
        stockReceivedModule,
        stockTransferModule,
        kazangSignUp,
        customInstall,
        billingModule,
        backOfficeModule,
        kitchenOrdersModule,
        ticketModule,
        timeModule,
        taxModule,
        ticket_notes_module
    },
    plugins: [
        vuexLocal.plugin,
    ],
})
export default store;

