<template>
  <div class="page">
    <button @click="reprintLastInvoice" class="reprint top-button" style="color: #475f9b">Reprint</button>
    <button @click="process" class="process top-button" :disabled="disabled" :style="saveColor">Process</button>
    <button @click="unselectCustomer" class="quote top-button" style="color: #475f9b" v-if="customer">Remove</button>
    <button @click="quote" class="quote top-button" style="color: #475f9b" v-if="!customer">Debtors</button>

    <CurrentSaleTable :show-back="true"/>
  </div>
</template>

<script>
import CurrentSaleTable from "@/components/point-of-sale/sales/CurrentSaleTable";

export default {
  name: "ViewSaleView",
  components: {CurrentSaleTable},
  computed: {
    customer(){
      const id = this.$store.getters.getCustomer;
      return this.$store.getters.getDebtorByID(id);
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount
    },
    disabled() {
      return this.saleTotal <= 0;
    },

    saveColor() {
      return this.disabled ? {color: 'red'} : {color: '#475f9b'}
    }
  },
  methods: {
    unselectCustomer() {
      this.$store.commit(`setCustomer`, '')
    },
    process() {
      return this.$router.push({name: 'payment'})
    },

    reprintLastInvoice() {
      return this.$store.dispatch(`reprintLastInvoice`);
    },

    async quote() {
      let isAllowed = await this.$store.dispatch(`validateSecurityPermission`, 'SellCustomers');
      if(!isAllowed) return;
      return this.$router.push({name: 'select-customer'})
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  background: white;
  height: 100%;
  position: relative;


  .top-button {
    position: absolute;
    background-color: transparent;
    top: 15px;
    z-index: 1;
    font-weight: 600;
  }

  .process{
    right:10px;
  }
  
  .quote{
    right: 100px;
  }

  .reprint{
    right: 180px;
  }
}
</style>
