<template>
  <div class="page">
    <div class="container d-flex justify-content-center">
      <div class="area">
        <form action="">
          <div class="form-floating mb-3">
            <input type="number" step="0.01" class="form-control" @input="setSplitTotal"
                   v-model="splitArray.cash" id="splitCash"
                   placeholder="Cash">
            <label for="splitCash">Cash Amount</label>
          </div>

          <div class="form-floating mb-3">
            <input type="number" step="0.01" class="form-control"  @input="setSplitTotal"
                   v-model="splitArray.card" id="splitCard"
                   placeholder="Card">
            <label for="splitCard">Card Amount</label>
          </div>

          <div class="form-floating mb-3" v-for="method in splitArray.other">
            <input type="number" step="0.01" class="form-control"  @input="setSplitTotal"
                   v-model="method.amount" :id="method.id+`-amount`"
                   :placeholder="`${method.name} Amount`">
            <label :for="method.id+`-total`"> <b>{{ method.name }} Amount`</b> </label>
          </div>

          <div class="form-floating mb-3" v-if="!Number(splitArray.Spenn > 0)">
            <input type="number" step="0.01" class="form-control"  @input="setSplitTotal"
                   v-model="splitArray.Kazang" id="splitKazang"
                   placeholder="Kazang">
            <label for="splitKazang">Kazang Amount</label>
          </div>
        </form>
      </div>
    </div>

    <b>Sale Total : {{ $utils.formatMoney(saleTotal - discount.total) }}</b><br>
    <b>Your Total : {{ $utils.formatMoney(splitTotalAmount) }}</b><br>
    <b>Amount Left : <b :style="color">{{ $utils.formatMoney(amountLeft) }}</b> </b><br>

    <div class="submit">
      <button :disabled="isNotValidSplitTotal && loading" :style="disabled" @click="processSplit" class="submit-btn lyt-soft-btn">
        <span v-if="loading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
        Submit
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SplitPaymentScreen",
  beforeMount() {
    this.splitArray.other = this.$store.getters.getPaymentMethods
        .filter(method => method.active)
        .map(method => {
          method.amount = 0;
          return method;
        });
  },
  data() {
    return {
      loading: false,
      splitTotalAmount : 0,
      splitArray: {
        cash: 0.00,
        card: 0.00,
        Kazang: 0.0,
        Spenn: 0.0,
        other : []
      }
    }
  },
  computed: {
    has543() {
      return this.$store.getters.getHas543;
    },

    hasKazang() {
      return this.$store.getters.getHasKazang;
    },
    saleDetails() {
      return this.$store.getters.getSaleDetails;
    },
    discount() {
      return this.$store.getters.getSaleDiscount;
    },
    saleTotal() {
      return this.$store.getters.getSaleTotal + this.$store.getters.getTipAmount
    },
    isNotValidSplitTotal() {
      return this.amountLeft > 0;
    },
    color() {
      return this.isNotValidSplitTotal ? {color: 'red'} : {color: 'green'}
    },

    disabled() {
      return this.isNotValidSplitTotal ? {background: '#9e9e9e', 'pointer-events': 'none'} : {}
    },

    amountLeft() {
      return parseFloat(this.saleTotal) - parseFloat(this.discount.total) - this.splitTotalAmount;
    },
  },
  methods: {
    processSplit() {
      let obj = this.saleDetails;
      this.splitArray.cash = Number(this.splitArray.cash)
      this.splitArray.card = Number(this.splitArray.card)
      this.splitArray.Kazang = Number(this.splitArray.Kazang)
      this.splitArray.Spenn = Number(this.splitArray.Spenn)
      this.splitArray.other = this.splitArray.other.map(method=>{
        method.amount= Number(method.amount);
        return method;
      });
      obj.splitArray = this.splitArray;

       if (Number(this.splitArray.Kazang) > 0){
        return this.splitWithKazang(obj)
      }else if (Number(this.splitArray.Spenn) > 0){
        return this.splitWithKazang(obj)
      }

      this.loading = true;
      return this.$store.dispatch("processTransaction", obj)
          .then((res) => {
            // if (res) this.$router.push({name: 'viewPayment'})
          })
          .finally(() => this.loading = false);
    },

    setSplitTotal() {
      let total =  (
          Number(this.splitArray.cash) +
          Number(this.splitArray.card) +
          Number(this.splitArray.Kazang)+
          Number(this.splitArray.Spenn)
      )
      for (let i = 0; i < this.splitArray.other.length; i++) {
        total += Number(this.splitArray.other[i].amount)
      }
      this.splitTotalAmount = total;
    },


    splitWithKazang(obj) {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          obj.customerContact = input;
          return this.$store.dispatch('firstKazangPaymentStep', obj)
              .then((res) => {
                if (res.Error) throw new Error(res.Error);
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'});
        }
      })
    },

    confirmKazangSplitPayment(firstKazangStepResponse) {
      if (firstKazangStepResponse === "processed"){
        return this.$router.push({name: 'viewPayment'})
      }
      return Swal.fire({
        title: 'Confirm Kazang Payment',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return this.$store.dispatch('secondKazangStep', firstKazangStepResponse)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
              })
              .catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },

    splitWithSpenn(obj) {
      return Swal.fire({
        title: 'Customer Phone No. ?',
        icon: 'question',
        input: 'number',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: (input) => {
          if (!input) return Swal.showValidationMessage(`Customer Phone No.`);
          obj.customerContact = input;
          return this.$store.dispatch('firstSpennPaymentStep', obj)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
                else return res;
              }).catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.confirmSpennSplitPayment(result.value)
        }
      })
    },

    confirmSpennSplitPayment(firstSpennStepResponse) {
      if (firstSpennStepResponse === "processed"){
        return this.$router.push({name: 'viewPayment'})
      }
      return Swal.fire({
        title: 'Confirm Spenn Payment',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Process',
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        preConfirm: () => {
          return this.$store.dispatch('secondSpennStep', firstSpennStepResponse)
              .then((res) => {
                if (res.Error) throw new Error(res.Error)
              })
              .catch(err => Swal.showValidationMessage(err))
        },
      }).then((result) => {
        if (result.isConfirmed) {
          return this.$router.push({name: 'viewPayment'})
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
  overflow-y: scroll;
}

.page {
  height: 100vh;
  overflow-y: scroll;
}

.line {
  padding-left: 33px;
}

.area {
  max-width: 424px;
  width: 100%;
}

.form-check-input {
  font-size: 22px;
}

.submit {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-bottom: 100px;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.submit-btn {
  color: white;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  border-radius: 10px;
  width: 100%;
  max-width: 350px;
  height: 50px;
  margin-top: 20px;
}
</style>