<template>
  <div class="page">
    <div class="banner">
      <div class="container d-flex justify-content-center"></div>
      <img style="height: 450px;" src="../../assets/img/KazangLogo.png" alt="">

    </div>

    <div class="container d-flex justify-content-center">
      <PosLoginForm class="mt-5"/>
    </div>

    <p class="text-center mt-5">Powered By <a target="_blank" href="https://chrilantech.com">Lyt-Soft POS</a></p>
    <p class="text-center mt-5"><a target="_blank" href="https://tawk.to/LytSupport">Contact Support</a></p>
  </div>
</template>

<script>
import PosLoginForm from "@/components/point-of-sale/PosLoginForm";

export default {
  name: "PosLoginView",
  components: {PosLoginForm},
}
</script>

<style scoped>
.page {
  background: #9ccc65;
  background: -moz-linear-gradient(180deg, #9ccc65 14%, rgba(255, 255, 255, 1) 14%);
  background: -webkit-linear-gradient(180deg, #9ccc65 14%, rgba(255, 255, 255, 1) 14%);
  background: linear-gradient(180deg, #9ccc65 14%, rgba(255, 255, 255, 1) 14%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#40434a", endColorstr="#ffffff", GradientType=1);
  overflow-y: scroll;
}
.banner {
  height: 8vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  background: #a9d15e;
  margin-top: 69px !important;
  max-height: 280px;
}
</style>
