import {Storage} from "@/services/Storage";
import axios from "axios";

export default {
    name: 'stock-received',
    state: {
        itemsToReceive: []
    },
    getters: {
        getItemsToReceive: (state) => state.itemsToReceive
    },

    mutations: {
        setItemsToReceive : (state, data) => state.itemsToReceive = data,
        addToReceivedItems: (state, data) => state.itemsToReceive.push(data),
        clearGrvData: (state) => {
            state.itemsToReceive = [];
            state.selectedSupplierCode = ''
        },
        removeFromGRV: (state , item) => {
            let plu = item.PLU;
            state.itemsToReceive = state.itemsToReceive
                .filter(stockItem => stockItem.PLU !== plu);
        }
    },
    actions: {
        createGRV: async (context, payload) => {
            let store = await Storage.getDefaultStore();
            let user = await Storage.getCurrentUser();
            let data = {
                supplier: context.getters.getSelectedSupplierCode === 'AUTO-CASH' ? "CASH" :
                    context.getters.getSelectedSupplierCode,
                items: payload.items,
                user: user.Username,
                pc: localStorage.getItem(`_capuid`),
            }
            console.log("Data being sent to API:", data);
            return axios.post(franchiseTomcatApi + `GRV/${store.StoreDB}/CreateGRV?isProcessed=true`, data)
                .then(({data}) => {
                    return Promise.all([
                        context.commit('clearGrvData'),
                        context.dispatch(`printGRV`,data)
                    ])
                }).catch((err) => errorHandler.tomcatError(err))
        },

        printGRV: async (context, grvNumber) => {
            let store = await Storage.getDefaultStore();
            return axios.get(franchiseTomcatApi + `GRV/${store.StoreDB}/getByGrvNumber/${grvNumber}`)
                .then(({data}) => {
                    try {
                        Printing.printGrv(JSON.stringify(data))
                    } catch (e) {}
                })
        }
    }
}
