import {Storage as Store} from '@capacitor/storage';
import {clear, get, set} from 'idb-keyval';
import store from "@/store/index";

export const ZRA_DETAILS = 'zra_details'
export const DEFAULT_STORE = 'default_store'
export const ALL_STORES = 'all_stores'
export const FRANCHISE_SETTINGS = 'franchise_settings'
export const REGISTRATION_DETAILS = 'registration_details'

export const ADMIN = 'admin'
export const CATEGORIES = 'categories'
export const AllUsers = 'all_users'
export const AllSecurities = 'securities'
export const CURRENT_STORE = 'currentStore'
export const CurrentUser = 'current_user'
export const STOCK = 'stock'
export const PAYMENT_METHODS = 'payment_methods'
export const STOCK_SETTING = 'stock_setting'

export const HAS543 = 'has543'
export const HASKAZANG = 'hasKazang'
export const KAZANGSESSION = 'kazangSession'
export const KAZANGPRODUCTS = 'kazangProductList'

export const FLOAT = 'float'
export const DEVICE_SETTINGS = 'device_settings'
export const SAVED_TABLES = 'saved_tables'

export const BASE_CURRENCY = 'base_currency'

export const DISCOUNT_SPECIALS = 'discountSpecials'
export const MULTI_SPECIALS = 'multiSpecials'
export const COMBO_SPECIALS = 'comboSpecials'

const notEmpty = (val) => {
    if (!val) return false
    return val !== ''
}
const encryptAdmin = (val) => {
    // return CryptoJS.AES.encrypt(val, 'Admin-User-Hello').toString()
    return val;
}
const decryptAdmin = (val) => {
    // return CryptoJS.AES.decrypt(val, 'Admin-User-Hello').toString(CryptoJS.enc.Utf8)
    return JSON.parse(val);
}

const getValue = async (key) => {
    // Check IndexedDB first
    let value = await get(key);
    if (value) return value;

    // Fall back to Capacitor Storage
    value = await Store.get({key});
    return value && value.value ? JSON.parse(value.value) : null;
};

const setValue = async (key, value) => {
    await set(key, value);
};

export const Storage = {
    setAdminUser: async (user) => {
        await Store.set({
            key: ADMIN, value: encryptAdmin(JSON.stringify(user))
        })
        await set(ADMIN, encryptAdmin(JSON.stringify(user)));
        store.commit('setAdminUser',user)
    },
    getAdminUser: async () => {
        let value = store.getters.getAdminUser;
        if (value) return value;

        // Check IndexedDB first
        value = await get(ADMIN);
        if (value) {
            return decryptAdmin(value)
        }
        value = await Store.get({
            key: ADMIN,
        });
        // Fall back to Capacitor Storage
        value = (value && value.value) ? value.value : null;
        return decryptAdmin(value);
    },


    setAllStores: async (payload) => {
        // store/index.js state: registeredStores
        await setValue(ALL_STORES, payload);
        store.commit('setRegisteredStores', payload);
    },

    getAllStores: async () => {
        let value = store.getters.getRegisteredStores;
        if (value) return value;
        value = await getValue(ALL_STORES);
        return value;
    },
    setCurrentStore: async (payload) => setValue(CURRENT_STORE, payload),
    getCurrentStore: async () => {
        return await getValue(CURRENT_STORE);
    },


    setFranchiseSettings: async (payload) => {
        await setValue(FRANCHISE_SETTINGS, payload);
        store.commit('setFranchiseSettings',payload);
    },

    setRegistrationDetails: async (payload) => {
        await setValue(REGISTRATION_DETAILS, payload);
        store.commit('setRegistrationDetails',payload);
    },

    getFranchiseSettings: async () => {
        let value = store.getters.getFranchiseSettings;
        if (value) return value;
        value = await getValue(FRANCHISE_SETTINGS);
        return value;
    },

    getRegistrationDetails: async () => {
        let value = store.getters.getRegistrationDetails;
        if (value) return value;
        value = await getValue(REGISTRATION_DETAILS);
        return value;
    },

    setZRADetails: async (payload) => {
        await Store.set({
            key: ZRA_DETAILS, value: JSON.stringify(payload)
        })
    },
    setCategories: async (categories) => {
        await setValue(CATEGORIES, categories);
        store.commit('setCategories', categories);
    },

    getCategories: async () => {
        let value = store.getters.getCategories;
        if (value) return value;
        value = await getValue(CATEGORIES);
        return value;
    },

    setHas543: async (has543) => {
        await Store.set({
            key: HAS543,
            value: JSON.stringify(has543)
        })
    },
    getHas543: async () => {
        const value = await Store.get({
            key: HAS543
        })
        return JSON.parse(value.value)
    },

    setHasKazang: async (payload) => {
        await setValue(HASKAZANG, payload);
        store.commit('setHasKazang',payload);
    },
    getHasKazang: async () => {
        const value = await Store.get({
            key: HASKAZANG
        })
        return JSON.parse(value.value)
    },

    getZRADetails: async () => {
        const value = await Store.get({
            key: ZRA_DETAILS
        })
        return JSON.parse(value.value)
    },
    setDefaultStore: async (defaultStore) => {
        await setValue(DEFAULT_STORE, defaultStore);
        store.commit('setDefaultStore',defaultStore);
    },
    getDefaultStore: async () => {
        let value = store.getters.getDefaultStore;
        if(value) return value;
        value = await getValue(DEFAULT_STORE);
        console.log(value)
        if (!value) return null;
        return value;
    },

    getCurrentStoreID: async () => {
        let store = await getValue(REGISTRATION_DETAILS);
        if (!store) return null;
        return store.id;
    },
    getCurrentStoreDB: async () => {
        if (!store.getters.isHydrated) {
            await new Promise((resolve) => {
                const interval = setInterval(() => {
                    // console.log(`is Hydrated`,store.getters.isHydrated)
                    if (store.getters.isHydrated) {
                        clearInterval(interval);
                        resolve();
                    }
                }, 50);  // Check every 50ms
            });
        }
        let defaultStore = store.getters.getDefaultStore;
        if (!defaultStore) return null;
        return defaultStore.StoreDB;
    },

    setAllUsers: async (users) => {
        // store/module/userModule.js state: users
        await setValue(AllUsers, users);
        store.commit('setAllUsers',users);
    },
    getAllUsers: async () => {
        let value = store.getters.getUsers;
        if (value) return value;
        value = await getValue(AllUsers);
        return value;
    },
    setAllSecurities: async (users) => {
        await setValue(AllSecurities, users);
        store.commit('setAllSecurities',users);
    },
    getAllSecurities: async () => {
        let value = store.getters.getSecurities;
        if (value) return value;
        value = await getValue(AllSecurities);
        return value;
    },

    setCurrentUser: async (currentUser) => {
        await setValue(CurrentUser, currentUser);
        store.commit('setCurrentUser',currentUser);
    },
    getCurrentUser: async () => {
        let value = store.getters.getCurrentUser;
        if (value) return value;
        value = await getValue(CurrentUser);
        return value;
    },
    setStock: async (stock) => {
        await Store.set({
            key: STOCK, value: JSON.stringify(stock)
        });
    },
    getAllStock: async () => {
        const value = await Store.get({
            key: STOCK
        });
        return JSON.parse(value.value);
    },
    getByPlu: async (plu) => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        return stock.find(item => item.PLU === plu)
    },
    getFastLine: async () => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        return stock.filter(sc => sc.FastLine === 1);
    },
    searchStockDesc: async (query) => {
        if (notEmpty(query.category)) {
            return (await Storage.searchStock(query))
                .filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                    || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                    || s.barcode.toLowerCase().includes(query.description.toLowerCase())
                ).sort((a, b) => {
                    return a.Description < b.Description;
                })
        }
        return (await Storage.getAllStock())
            .filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                || s.barcode.toLowerCase().includes(query.description.toLowerCase())
            ).sort((a, b) => {
                return a.Description < b.Description;
            })

    },
    searchStock: async (query) => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value) ?? [];
        let result = [];
        if (query.category !== '' && query.subCategory !== '' && query.sub2Category !== '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase())
                .filter(s2 => s2.SubDep.toLowerCase() === query.subCategory.toLowerCase())
                .filter(s3 => s3.Subdep2.toLowerCase() === query.sub2Category.toLowerCase());
        } else if (query.category !== '' && query.subCategory !== '' && query.sub2Category === '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase())
                .filter(s2 => s2.SubDep.toLowerCase() === query.subCategory.toLowerCase())
        } else if (query.category !== '' && query.sub2Category === '' && query.subCategory === '') {
            result = stock.filter(s => s.Department.toLowerCase() === query.category.toLowerCase());
        }
        if (query.description) {
            return result.filter(s => s.Description.toLowerCase().includes(query.description.toLowerCase())
                || s.PLU.toLowerCase().includes(query.description.toLowerCase())
                || s.barcode.toLowerCase().includes(query.description.toLowerCase())
            )
        }
        return result.sort((a, b) => {
            return a.Description < b.Description;
        });
    },
    editStockItem: async payload => {
        const value = await Store.get({
            key: STOCK
        });
        let stock = JSON.parse(value.value);
        stock = stock.map(s => {
            if (s.PLU === payload.PLU) {
                return payload
            }
            return s;
        })
        await Storage.setStock(stock);
    },
    setFloat: async (payload) => {
        await setValue(FLOAT, payload)
        store.commit('setUserFloat', payload);
    },
    getFloat: async () => {
        let value = store.getters.getFloats;
        if (value) return value;
        value = await getValue(FLOAT);
        return value;
    },
    setDeviceSettings: async (payload) => {
        await setValue(DEVICE_SETTINGS, payload)
        store.commit('setDeviceSettings', payload)
    },

    getDeviceSettings: async () => {
        let value = store.getters.getDeviceSettings;
        if (value) return value;
        value = await getValue(DEVICE_SETTINGS);
        return value;
    },
    getAllTables: async () => {
        let value = store.getters.getAllTables;
        if (value) return value;
        value = await getValue(SAVED_TABLES);
        return value;
    },

    setTables: async (payload) => {
        await setValue(SAVED_TABLES, payload)
    },

    createTable: async (payload) => {
        let value = await Store.get({
            key: SAVED_TABLES
        });
        if (!value) {
            value = {
                value: JSON.stringify([]),
            }
        }
        let tables = JSON.parse(value.value);
        if (!tables) {
            tables = []
        }
        tables.push(payload)
        await Store.set({
            key: SAVED_TABLES,
            value: JSON.stringify(tables)
        });
    },

    editTable: async (payload) => {
        const value = await Store.get({
            key: SAVED_TABLES
        });
        let tables = JSON.parse(value.value);
        tables = tables.map(table => {
            if (table.SaleNum === payload.SaleNum) {
                return payload
            }
            return table;
        })
        await Store.set({
            key: SAVED_TABLES,
            value: JSON.stringify(tables)
        });
    },

    deleteTable: async (salenum) => {
        const value = await Store.get({
            key: SAVED_TABLES
        });
        let tables = JSON.parse(value.value);
        tables = tables.filter(table => table.SaleNum !== salenum);
        await Store.set({
            key: SAVED_TABLES,
            value: JSON.stringify(tables)
        });
    },

    clear: async () => Store.clear(),
    setStockSettings: async (stock) => {
        await setValue(STOCK_SETTING, stock);
        store.commit('setStockSetting', stock);
    },
    getStockSetting: async (plu) => {
        let settings = store.getters.getStockSetting;
        if(settings) return settings.find(set => set.plu === plu);
        settings = await getValue(STOCK_SETTING)
        return settings.find(set => set.plu === plu);
    },


    setPaymentMethods: async (data) => {
        await setValue(PAYMENT_METHODS, data);
        store.commit('setPaymentMethods', data);
    },
    getPaymentMethods: async () => {
        let value = store.getters.getPaymentMethods;
        if (value) return value;
        value = await getValue(PAYMENT_METHODS);
        return value;
    },

    setKazangSession: async (data) => {
        await setValue(KAZANGSESSION, data);
        store.commit('setKazangSession', data);
    },
    getKazangSession: async () => {
        let value = store.getters.getKazangSessionID;
        if (value) return value;
        value = await getValue(KAZANGSESSION);
        return value;
    },

    setKazangProductList: async (data) => {
        await Store.set({
            key: KAZANGPRODUCTS,
            value: JSON.stringify(data)
        });
    },
    getKazangProductList: async () => {
        const value = await Store.get({
            key: KAZANGPRODUCTS
        });
        return JSON.parse(value.value);
    },

    setBaseCurrency: async (data) => {
        await setValue(BASE_CURRENCY, data)
        store.commit('setBaseCurrency', data);
    },
    getBaseCurrency: async () => {
        let value = store.getters.getBaseCurrency;
        if (value) return value;
        value = await getValue(BASE_CURRENCY);
        return value;
    },

    setDiscountSpecials: async (payload) => {
        setValue(DISCOUNT_SPECIALS, payload)
        store.commit('setDiscountSpecials', payload);
    },
    getDiscountSpecials: async () => {
        let value = store.getters.getDiscountSpecials;
        if (value) return value;
        value = await getValue(DISCOUNT_SPECIALS);
        return value;
    },

    setMultiSpecials: async (payload) => {
        setValue(MULTI_SPECIALS, payload)
        store.commit('setMultiSpecials', payload);
    },
    getMultiSpecials: async () => {
        let value = store.getters.getMultiSpecials;
        if (value) return value;
        value = await getValue(MULTI_SPECIALS);
        return value;
    },
    setComboSpecials: async (payload) => {
        setValue(COMBO_SPECIALS, payload)
        store.commit('setComboSpecials', payload);
    },
    getComboSpecials: async () => {
        let value = store.getters.getComboSpecials;
        if (value) return value;
        value = await getValue(COMBO_SPECIALS);
        return value;
    },
}
